import { AppSessionCellId, CellId } from "@hex/common";
import { shallowEqual } from "react-redux";

import { useAppSessionCellsSelector } from "../../appsession-multiplayer/state-hooks/appSessionCellsStateHooks";
import { AppSessionCellMP } from "../../redux/slices/appSessionMPSlice";

/**
 * Map from cellId -> appSessionCellId for the current appSession
 */
export function useAppSessionCellIdMap(): Record<CellId, AppSessionCellId> {
  const appSessionCellMap = useAppSessionCellsSelector({
    // TODO(redux-cleanup): move to state-hooks and use a redux selector so that this computation is cached
    selector: (
      appSessionCells: Record<AppSessionCellId, AppSessionCellMP | undefined>,
    ) => {
      const map: Record<CellId, AppSessionCellId> = {};
      Object.values(appSessionCells ?? {}).forEach((appSessionCell) => {
        if (appSessionCell != null && appSessionCell.deletedDate == null) {
          map[appSessionCell.cellId] = appSessionCell.id;
        }
      });
      return map;
    },
    equalityFn: shallowEqual,
  });

  return appSessionCellMap;
}
