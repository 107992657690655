import {
  CreateMetricCellMandatoryFields,
  DEFAULT_CHART_RESULT_VARIABLE,
  DISPLAY_TABLE_DEFAULTS,
  DataConnectionId,
  notEmpty,
} from "@hex/common";
import { useCallback } from "react";

import { useCellsContentsGetter } from "../../hex-version-multiplayer/state-hooks/cellsContentsStateHooks";
import { useHexVersionGetter } from "../../hex-version-multiplayer/state-hooks/hexVersionStateHooks";
import { useStore } from "../../redux/hooks.js";

import {
  useGetUniqueVariableName,
  useGetUniqueVariableNameForMetricCell,
} from "./useGetUniqueVariableName";

export interface NewSqlCellFields {
  // a new unique result variable that shouldn't conflict
  resultVariable: string;
  // the last used connection id
  connectionId?: DataConnectionId;
  castDecimals: boolean;
  dataFrameCell: boolean;
}

export interface GetNewSqlCellOptions {
  // pass if you want the new cell's name to be based off of some previous name
  originalName?: string;
  // pass if you're generating names for multiple cells in a loop (to avoid the same name being generated each time)
  extraExistingNames?: string[];
}
export interface NewDbtMetricCellFields {
  // a new unique result variable that shouldn't conflict
  resultVariable: string;
  // the last used connection id
  connectionId?: DataConnectionId;
  castDecimals: boolean;
}
export interface GetNewDbtMetricCellOptions {
  // pass if you want the new cell's name to be based off of some previous name
  originalName?: string;
  // pass if you're generating names for multiple cells in a loop (to avoid the same name being generated each time)
  extraExistingNames?: string[];
}

export interface NewPivotCellFields {
  resultVariable: string;
  castDecimals: boolean;
}
export interface GetNewPivotCellOptions {
  originalName?: string;
}

export interface NewFilterCellFields {
  resultVariable: string;
  castDecimals: boolean;
}

export interface GetNewFilterCellOptions {
  originalName?: string;
}

export interface NewChartCellFields {
  resultVariable: string;
}

export interface GetNewChartCellOptions {
  originalName?: string;
}

export const DEFAULT_SQL_DATAFRAME_NAME = "dataframe";
/**
 * Hook that creates a callback to make new sql cell creation payloads.
 *
 * Some fields of a SQL cell require some contextual information from the hex
 * to determine what is 'best' for an initial value.
 */
export function useGetNewSqlCellFields(): (
  options?: GetNewSqlCellOptions,
) => NewSqlCellFields {
  const getCellContents = useCellsContentsGetter();
  const getVariableName = useGetUniqueVariableName("SqlCell");
  const getCastDecimalsDefault = useHexVersionGetter({
    selector: (hexVersion) => hexVersion.castDecimalsDefault,
  });

  const store = useStore();

  return useCallback(
    ({
      extraExistingNames,
      originalName = DEFAULT_SQL_DATAFRAME_NAME,
    } = {}) => {
      const sqlCells =
        Object.values(getCellContents())
          .filter(notEmpty)
          .filter((c) => c.deletedDate == null)
          .flatMap((c) => (c.__typename === "SqlCell" ? c : []))
          // only keep SQL cells that actually have a connection specified
          .filter((c) => c.connectionId != null || c.dataFrameCell)
          .sort(
            (a, b) =>
              new Date(b.createdDate).getTime() -
              new Date(a.createdDate).getTime(),
          ) ?? [];

      const resultVariable = getVariableName(originalName, extraExistingNames);

      let connectionId: DataConnectionId | undefined;
      let dataFrameCell = false;

      const lastUsedConnectionId =
        store.getState().logicView.lastUsedDataConnection;

      // If the last sql cell added was a dataframe cell, then we default to
      // match the previous sql cell. Otherwise we default to the last used
      // connection.
      const cell = sqlCells[0];
      if (cell != null && cell.dataFrameCell) {
        connectionId = cell.connectionId;
        dataFrameCell = cell.dataFrameCell;
      } else if (lastUsedConnectionId) {
        connectionId = lastUsedConnectionId;
      }

      const castDecimalsDefault = getCastDecimalsDefault();

      return {
        resultVariable,
        connectionId,
        castDecimals: castDecimalsDefault,
        dataFrameCell,
      };
    },
    [getCellContents, getVariableName, store, getCastDecimalsDefault],
  );
}

/**
 * Hook that creates a callback to make new metric cell creation payloads.
 *
 * Some fields of a dbt metric cell require some contextual information from the hex
 * to determine what is 'best' for an initial value.
 */
export function useGetNewDbtMetricCellFields(): (
  options?: GetNewDbtMetricCellOptions,
) => NewDbtMetricCellFields {
  const getCellContents = useCellsContentsGetter();
  const getVariableName = useGetUniqueVariableName("DbtMetricCell");
  const getCastDecimalsDefault = useHexVersionGetter({
    selector: (hexVersion) => hexVersion.castDecimalsDefault,
  });

  return useCallback(
    ({ extraExistingNames, originalName = "metric_result" } = {}) => {
      const dbtMetricCells =
        Object.values(getCellContents())
          .filter(notEmpty)
          .filter((c) => c.deletedDate == null)
          .flatMap((c) => (c.__typename === "DbtMetricCell" ? c : []))
          .sort(
            (a, b) =>
              new Date(b.createdDate).getTime() -
              new Date(a.createdDate).getTime(),
          ) ?? [];

      const resultVariable = getVariableName(originalName, extraExistingNames);

      const connectionId = dbtMetricCells[0]?.connectionId;

      const castDecimalsDefault = getCastDecimalsDefault();

      return {
        resultVariable,
        connectionId,
        castDecimals: castDecimalsDefault,
      };
    },
    [getCellContents, getVariableName, getCastDecimalsDefault],
  );
}

export const DEFAULT_PIVOT_RESULT_VARIABLE = "pivot_result";
export function useGetNewPivotCellFields(): (
  options?: GetNewPivotCellOptions,
) => NewPivotCellFields {
  const getVariableName = useGetUniqueVariableName("PivotCell");
  const getCastDecimalsDefault = useHexVersionGetter({
    selector: (hexVersion) => hexVersion.castDecimalsDefault,
  });
  return useCallback(
    ({ originalName = DEFAULT_PIVOT_RESULT_VARIABLE } = {}) => {
      return {
        resultVariable: getVariableName(originalName),
        castDecimals: getCastDecimalsDefault(),
      };
    },
    [getVariableName, getCastDecimalsDefault],
  );
}

export const DEFAULT_FILTER_RESULT_VARIABLE = "filter_result";
export function useGetNewFilterCellFields(): (
  options?: GetNewFilterCellOptions,
) => NewFilterCellFields {
  const getVariableName = useGetUniqueVariableName("FilterCell");
  const getCastDecimalsDefault = useHexVersionGetter({
    selector: (hexVersion) => hexVersion.castDecimalsDefault,
  });
  return useCallback(
    ({ originalName = DEFAULT_FILTER_RESULT_VARIABLE } = {}) => {
      return {
        resultVariable: getVariableName(originalName),
        castDecimals: getCastDecimalsDefault(),
      };
    },
    [getCastDecimalsDefault, getVariableName],
  );
}

export function useGetNewChartCellFields(): (
  options?: GetNewChartCellOptions,
) => NewChartCellFields {
  const getVariableName = useGetUniqueVariableName("ChartCell");
  return useCallback(
    ({ originalName = DEFAULT_CHART_RESULT_VARIABLE } = {}) => {
      return {
        resultVariable: getVariableName(originalName),
      };
    },
    [getVariableName],
  );
}
export const DEFAULT_DISPLAY_TABLE_RESULT_VARIABLE = "table_result";
export function useGetNewDisplayTableCellFields(): (
  options?: GetNewChartCellOptions,
) => NewChartCellFields {
  const getVariableName = useGetUniqueVariableName("DisplayTableCell");
  return useCallback(
    ({ originalName = DEFAULT_DISPLAY_TABLE_RESULT_VARIABLE } = {}) => {
      return {
        resultVariable: getVariableName(originalName),
        pageSize: DISPLAY_TABLE_DEFAULTS.pageSize,
      };
    },
    [getVariableName],
  );
}

export function useGetNewMetricCellFields(): (
  outputResult: boolean,
) => CreateMetricCellMandatoryFields {
  const getResultVariableName = useGetUniqueVariableNameForMetricCell();
  return useCallback(
    (outputResult: boolean) => ({
      valueResultVariable: outputResult
        ? getResultVariableName("value")
        : undefined,
      comparisonResultVariable: outputResult
        ? getResultVariableName("comparison")
        : undefined,
    }),
    [getResultVariableName],
  );
}
