import { useCallback } from "react";

import { useStore } from "../../redux/hooks.js";
import {
  CellMP,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import { useProjectContext } from "../../util/projectContext.js";

export type UseCellOrdersGetterArgs = {
  /** Whether to ignore component children cells or not*/
  ignoreComponentChildCells?: boolean;
  /** Whether to ignore block children cells or not */
  ignoreBlockChildCells?: boolean;
};

export type UseCellOrdersGetterResult = () => readonly CellMP[];

export function useCellOrdersGetter(
  args: UseCellOrdersGetterArgs = {},
): UseCellOrdersGetterResult {
  const { hexVersionId } = useProjectContext();
  const store = useStore();

  return useCallback(() => {
    let cells = hexVersionMPSelectors
      .getCellSelectors(hexVersionId)
      .selectFlattenedSorted(store.getState());

    if (args.ignoreComponentChildCells) {
      cells = cells.filter((c) => c.parentComponentImportCellId == null);
    }

    if (args.ignoreBlockChildCells) {
      cells = cells.filter((c) => c.parentBlockCellId == null);
    }
    return cells;
  }, [
    args.ignoreBlockChildCells,
    args.ignoreComponentChildCells,
    hexVersionId,
    store,
  ]);
}
