import { gql, useApolloClient } from "@apollo/client";
import { Intent } from "@blueprintjs/core";
import {
  AirlockBlockConfig,
  CellId,
  HexVersionAtomicOperation,
  SQLCellBlockConfig,
  UPDATE_CODE_CELL,
  UPDATE_SQL_CELL,
  assertNever,
  typedObjectEntries,
} from "@hex/common";
import { editor as Editor, Selection } from "monaco-editor";
import { useCallback } from "react";

import { useToaster } from "../../components/common/Toasts";
import { useCellContentsGetter } from "../../hex-version-multiplayer/state-hooks/cellContentsStateHooks";
import { useCellGetter } from "../../hex-version-multiplayer/state-hooks/cellStateHooks";
import {
  SafeCodeCellMpFragment,
  SafeSqlCellMpFragment,
} from "../../redux/slices/hexVersionMPSlice";
import { getModel } from "../../state/models/useModel";
import { useHexVersionAOContext } from "../../util/hexVersionAOContext";
import { useProjectContext } from "../../util/projectContext";

import { useCellOrdersGetter } from "./useCellOrdersGetter";
import {
  GetFormattedCellSourcesDocument,
  GetFormattedCellSourcesQuery,
  GetFormattedCellSourcesQueryVariables,
} from "./useFormatCell.generated";
import { useSortedSelectedCellsGetter } from "./useSelectCell";

gql`
  query GetFormattedCellSources(
    $cellIds: [CellId!]!
    $cellSources: [String!]!
    $hexVersionId: HexVersionId!
  ) {
    getFormattedCellSourcesV2(
      cellIds: $cellIds
      cellSources: $cellSources
      hexVersionId: $hexVersionId
    ) {
      cellId
      formattedSource
      failed
    }
  }
`;

interface HandleCellFormatResultArgs {
  cellsById: Record<CellId, SafeCodeCellMpFragment | SafeSqlCellMpFragment>;
  orderedCellIds: CellId[];
  formattedCellSources: readonly string[];
  modelOverride?: Editor.ITextModel;
}

export interface UseFormatCellsResult {
  formatSelectedCells: (modelOverride?: Editor.ITextModel) => void;
  formatAllCells: () => void;
  formatCellById: (args: {
    cellId: CellId;
    sourceOverride?: string;
    suppressErrorToast?: boolean;
  }) => void;
}

export function useFormatCells(): UseFormatCellsResult {
  const { dispatchAO: dispatchAO } = useHexVersionAOContext();
  const getSortedSelectedCells = useSortedSelectedCellsGetter();
  const getCellContents = useCellContentsGetter({ safe: true });
  const getAllOrderedCells = useCellOrdersGetter();
  const getCell = useCellGetter();
  const client = useApolloClient();
  const { hexVersionId } = useProjectContext();
  const toaster = useToaster();

  const updateFormattedCells = useCallback(
    ({
      cellsById,
      formattedCellSources,
      orderedCellIds,
    }: HandleCellFormatResultArgs) => {
      const updateCellOps: HexVersionAtomicOperation[] = [];
      orderedCellIds.forEach((cellId, idx) => {
        const source = formattedCellSources[idx];
        if (source.length > 0) {
          const cellContents = cellsById[cellId];
          if (cellContents.__typename === "CodeCell") {
            updateCellOps.push(
              UPDATE_CODE_CELL.create({
                codeCellId: cellContents.codeCellId,
                cellId: cellId,
                key: "source",
                value: source,
              }),
            );
          } else if (cellContents.__typename === "SqlCell") {
            updateCellOps.push(
              UPDATE_SQL_CELL.create({
                sqlCellId: cellContents.sqlCellId,
                cellId,
                key: "source",
                value: source,
              }),
            );
          }
        }
      });
      dispatchAO(updateCellOps);
    },
    [dispatchAO],
  );

  const pushEditToFormattedCell = useCallback(
    ({
      formattedCellSources,
      modelOverride,
      orderedCellIds,
    }: HandleCellFormatResultArgs) => {
      if (orderedCellIds.length !== 1) {
        throw new Error("Can only push edit to one cell");
      }
      const cellId = orderedCellIds[0];
      const model = modelOverride ?? getModel(cellId);
      if (!model) {
        return;
      }
      const fullRange = model.getFullModelRange();
      const selection = new Selection(
        fullRange.startLineNumber,
        fullRange.startColumn,
        fullRange.endLineNumber,
        fullRange.endColumn,
      );
      model.pushEditOperations(
        [selection],
        [
          {
            range: selection,
            text: formattedCellSources[0],
          },
        ],
        () => [],
      );
    },
    [],
  );

  const formatCells = useCallback(
    ({
      callback,
      cellIds,
      modelOverride,
      sourceOverride,
      suppressErrorToast,
    }: {
      cellIds: CellId[];
      callback: (args: HandleCellFormatResultArgs) => void;
      modelOverride?: Editor.ITextModel;
      sourceOverride?: string;
      suppressErrorToast: boolean;
    }): void => {
      if (
        (modelOverride != null || sourceOverride != null) &&
        cellIds.length !== 1
      ) {
        throw new Error("Can only override model or source for one cell");
      }
      if (modelOverride != null && sourceOverride != null) {
        throw new Error("Can only override model OR source for one cell");
      }

      let cellsById: Record<
        CellId,
        SafeCodeCellMpFragment | SafeSqlCellMpFragment
      > = {};

      if (modelOverride != null) {
        const cellId = cellIds[0];
        const cellContents = getCellContents(cellId);

        if (cellContents?.__typename === "BlockCell") {
          if (cellContents.blockConfig == null) {
            throw new Error(`Block cell ${cellId} is missing block config`);
          } else if (SQLCellBlockConfig.guard(cellContents.blockConfig)) {
            const sqlCellId = cellContents.blockConfig.sqlCellId;
            const sqlCellContents = getCellContents(sqlCellId);
            if (
              sqlCellContents != null &&
              sqlCellContents?.__typename === "SqlCell"
            ) {
              cellsById = {
                [sqlCellId]: {
                  ...sqlCellContents,
                  source: modelOverride.getValue(),
                } as SafeCodeCellMpFragment | SafeSqlCellMpFragment,
              };
            }
          } else if (AirlockBlockConfig.guard(cellContents.blockConfig)) {
            //TODO(HAL-962): Ensure users can't attempt to format parent airlock
            //block cell
            return;
          } else {
            assertNever(cellContents.blockConfig, cellContents.blockConfig);
          }
        } else {
          cellsById = {
            [cellId]: {
              ...getCellContents(cellId),
              source: modelOverride.getValue(),
            } as SafeCodeCellMpFragment | SafeSqlCellMpFragment,
          };
        }
      } else {
        cellsById = cellIds.reduce(
          (acc, cellId) => {
            const cellContents = getCellContents(cellId);
            const cell = getCell(cellId);
            if (cell.parentComponentImportCellId == null) {
              if (cellContents?.__typename === "CodeCell") {
                acc[cellId] = cellContents;
              } else if (cellContents?.__typename === "SqlCell") {
                acc[cellId] = cellContents;
              } else if (cellContents?.__typename === "BlockCell") {
                if (cellContents.blockConfig == null) {
                  throw new Error(
                    `Block cell ${cellId} is missing block config`,
                  );
                } else if (SQLCellBlockConfig.guard(cellContents.blockConfig)) {
                  const sqlCellId = cellContents.blockConfig.sqlCellId;
                  const sqlCellContents = getCellContents(sqlCellId);
                  if (
                    sqlCellContents != null &&
                    sqlCellContents?.__typename === "SqlCell"
                  ) {
                    acc[sqlCellId] = sqlCellContents;
                  }
                } else if (AirlockBlockConfig.guard(cellContents.blockConfig)) {
                  //TODO(HAL-952): determine airlock support
                  throw new Error(
                    "Airlock block cells should not be formatted, child cells should be formatted instead",
                  );
                } else {
                  assertNever(
                    cellContents.blockConfig,
                    cellContents.blockConfig,
                  );
                }
              }
            }
            return acc;
          },
          {} as Record<CellId, SafeCodeCellMpFragment | SafeSqlCellMpFragment>,
        );
      }

      const cellsToFormat: CellId[] = [];
      const sourcesToFormat: string[] = [];
      typedObjectEntries(cellsById).forEach(([cellId, cellContents]) => {
        cellsToFormat.push(cellId);
        sourcesToFormat.push(sourceOverride ?? cellContents.source);
      });

      client
        .query<
          GetFormattedCellSourcesQuery,
          GetFormattedCellSourcesQueryVariables
        >({
          query: GetFormattedCellSourcesDocument,
          variables: {
            hexVersionId,
            cellIds: cellsToFormat,
            cellSources: sourcesToFormat,
          },
        })
        .then((result) => {
          const cellResults = result.data.getFormattedCellSourcesV2;
          callback({
            cellsById,
            orderedCellIds: cellsToFormat,
            formattedCellSources: cellResults.map((r) => r.formattedSource),
            modelOverride,
          });
          const failureCount = cellResults.filter((r) => r.failed).length;
          if (failureCount > 0 && !suppressErrorToast) {
            const cells = Object.values(cellsById);
            // If formatting a single cell, show a targeted error message
            if (cells.length === 1) {
              if (cells[0].__typename === "CodeCell") {
                toaster.show({
                  message:
                    "Failed to format cell. This may be due to a syntax error.",
                  intent: Intent.DANGER,
                });
              } else {
                toaster.show({
                  message:
                    "SQLFluff was unable to format this cell. This may be due to a syntax error.",
                  intent: Intent.DANGER,
                });
              }
            } else {
              toaster.show({
                message: `${failureCount} of ${cells.length} cells failed to format. This may be due to a syntax error.`,
                intent: Intent.DANGER,
              });
            }
          }
        })
        .catch((err) => {
          console.error(err);
          toaster.show({
            message: "Failed to format cell(s)",
            intent: Intent.DANGER,
          });
        });
    },
    [client, getCell, getCellContents, hexVersionId, toaster],
  );

  const formatSelectedCellsCallback = useCallback(
    (modelOverride?: Editor.ITextModel) => {
      const selectedCells = getSortedSelectedCells();
      formatCells({
        cellIds: selectedCells.map((c) => c.id),
        callback:
          selectedCells.length === 1
            ? pushEditToFormattedCell
            : updateFormattedCells,
        modelOverride,
        suppressErrorToast: false,
      });
    },
    [
      getSortedSelectedCells,
      formatCells,
      pushEditToFormattedCell,
      updateFormattedCells,
    ],
  );

  const formatAllCellsCallback = useCallback(() => {
    const allCells = getAllOrderedCells();
    formatCells({
      cellIds: allCells.map((c) => c.id),
      callback: updateFormattedCells,
      suppressErrorToast: false,
    });
  }, [getAllOrderedCells, formatCells, updateFormattedCells]);

  const formatCellByIdCallback = useCallback(
    ({
      cellId,
      sourceOverride,
      suppressErrorToast = false,
    }: {
      cellId: CellId;
      sourceOverride?: string;
      suppressErrorToast?: boolean;
    }) => {
      formatCells({
        cellIds: [cellId],
        callback: pushEditToFormattedCell,
        sourceOverride,
        suppressErrorToast,
      });
    },
    [formatCells, pushEditToFormattedCell],
  );

  return {
    formatSelectedCells: formatSelectedCellsCallback,
    formatAllCells: formatAllCellsCallback,
    formatCellById: formatCellByIdCallback,
  };
}
