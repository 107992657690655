import { ParameterName, notEmpty } from "@hex/common";
import { useCallback } from "react";

import { useCellsContentsGetter } from "../../hex-version-multiplayer/state-hooks/cellsContentsStateHooks";

// get a unique name for a new input cell, first trying the provided name
// if specified
export function useGetUniqueInputCellName(): (name?: string) => ParameterName {
  const getCellsContents = useCellsContentsGetter();

  return useCallback(
    (name) => {
      const inputCells = Object.values(getCellsContents())
        .filter(notEmpty)
        .flatMap((c) => (c.__typename === "Parameter" ? c : []));

      const existingInputNames = new Set(
        inputCells.map((inputCell) => inputCell.name),
      );
      let count = 0;
      let testName = name;
      if (name == null) {
        count++;
        testName = `input_${count}`;
      }
      while (existingInputNames.has(testName as ParameterName)) {
        count++;
        testName = `input_${count}`;
      }

      return testName as ParameterName;
    },
    [getCellsContents],
  );
}
